import { Alert } from '@mui/material';
import { Snackbar } from "@mui/material"
import { useSelector } from "react-redux";

const Notification = () => {
  const notification = useSelector(({ notification }) => notification)
  if (!notification.msg) return null

  const { msg, type } = notification

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      data-cy="notification"
    >
      <Alert variant="filled" severity={type}>
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default Notification
import React, {useReducer} from "react";
import {BASE_PATH, RESET_STEP2_URL,} from "../../resources/ApiUrls";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import {StorageManager} from "../../utils/index";
import {
  CHANGE_PASS_REQ,
  IS_AUTH,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_REMEMBER,
  LOGOUT,
  REMOVE_FIRST_TIME,
  RESET1_SUCCESS,
  RESET2_SUCCESS,
} from "../../types";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import LoginModal from "../../modal/LoginModal";
import LoginService from "../../services/LoginService";

const storageManager = new StorageManager();
const AuthState = (props) => {
  const initialState = {
    token: storageManager.getToken(),
    authenticated: null,
    message: null,
    // eslint-disable-next-line camelcase
    change_req: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (params) => {
    const bodyLogin = new LoginModal(
      params.username,
      params.password,
      "a",
      params["remember-me"] === true ? 1 : 0
    );
    try {
      const response = await LoginService.login(bodyLogin.createToJSON);
      storageManager.storePwd(params.password)
      if (response.data.data.change_password_required === 1) {
        dispatch({
          type: CHANGE_PASS_REQ,
          payload: response.data,
        });
      } else if (params["remember-me"] === true) {
        dispatch({
          type: LOGIN_SUCCESS_REMEMBER,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      const alert = {
        message: error.response.data.message,
        category: "alert-error",
      };

      dispatch({
        type: LOGIN_ERROR,
        payload: alert,
      });
     
    }
  };

  const reset = async (params) => {
    try {
      const response = await MyAxiosInstance.put(
        `${BASE_PATH}/auth/reset-step1`,
        params
      );
      dispatch({
        type: RESET1_SUCCESS,
        payload: response,
      });
    } catch (error) {
      const alert = {
        message: error.response.data.message,
        category: "alert-error",
      };

      dispatch({
        type: LOGIN_ERROR,
        payload: alert,
      });
    }
  };

  const resetStep2 = async (params) => {
    try {
      const response = await MyAxiosInstance.put(RESET_STEP2_URL, params);
      dispatch({type: RESET2_SUCCESS, payload: response.data});
    } catch (error) {
   
      // eslint-disable-next-line no-unused-vars
      const alert = {
        message: error.response.data.message,
        category: "alert-error",
      };
    }
    dispatch({
      type: LOGIN_ERROR,
      payload: alert,
    });
  };

  const checkAuth = async () => {
    var isAuth = storageManager.getToken();

    if (isAuth) {
      dispatch({
        type: IS_AUTH,
      });
    }
  };

  const logout = () => {
    storageManager.removeToken()
    localStorage.removeItem("user")
    window.location.reload(false);

    dispatch({
      type: LOGOUT,
    });
  };

  const removeFirstTime = () => {
    dispatch({
      type: REMOVE_FIRST_TIME,
    });
  }

  return (
    <AuthContext.Provider
      value={ {
        token: state.token,
        authenticated: state.authenticated,
        // eslint-disable-next-line camelcase
        change_req: state.change_req,
        user: state.user,
        message: state.message,
        login,
        reset,
        resetStep2,
        logout,
        checkAuth,
        removeFirstTime
      } }
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
